import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { AquacomComponentProduct, MooringProductType } from 'models';
import { MooringProductTypeService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject()
export class MooringProductTypeContainer {
  protected headers: {
    title: string;
  }[] = [];

  protected aquacomProducts: AquacomComponentProduct[] = [];

  constructor(
    private errorService: ErrorService,
    private mooringProductTypeService: MooringProductTypeService,
    private toast: ToastService,
    private t: I18N,
    private confirm: ConfirmDialog
  ) {}

  protected attached() {
    this.createHeaders();
    //
    void this.getProductTypes();
    void this.getAquacomProductTypes();
  }

  protected categories: MooringProductType[] = [];
  protected async getProductTypes() {
    try {
      this.categories = await this.mooringProductTypeService.getAll('?$expand=ArticleType($expand=Category)');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getAquacomProductTypes() {
    try {
      this.aquacomProducts = await this.mooringProductTypeService.getAquacomTypes();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        const confirmed = await this.confirm.confirmDelete();
        if (!confirmed) {
          return;
        }
        await this.mooringProductTypeService.delete(id);
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getProductTypes();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(type: MooringProductType) {
    try {
      if (type.Id) {
        await this.mooringProductTypeService.put(type, type.Id);
      } else {
        await this.mooringProductTypeService.post(type);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getProductTypes();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected isCreating = false;
  protected editingType: number | undefined = undefined;
  protected setEditingType(id: number) {
    this.isCreating = false;
    this.editingType = id;
  }

  protected setIsCreatingType(isCreating: boolean) {
    this.editingType = undefined;
    this.isCreating = isCreating;
  }

  private createHeaders() {
    this.headers = [
      { title: this.t.tr('general.category')},
      { title: this.t.tr('general.type')},
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.name-en') },
      { title: this.t.tr('general.name-es') },
      { title: this.t.tr('general.aquacomId') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
